/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import { NAMES as LOGOTYPE_NAMES, SIZES } from "../../atoms/Logotype";
import PageAnchor from "../../atoms/PageAnchor";
import PageScrollbar from "../../atoms/PageScrollbar";
import Footer from "../../organisms/Footer";
import Header from "../../organisms/Header";
import MobileFooter from "../../organisms/Mobile/Footer";
import MobileHeader from "../../organisms/Mobile/Header";
import { useDocumentScroll, useModal, useQuery } from "../../../hooks";
import classes from "./Layout.module.scss";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import useMyMaor from "../../pages/MyMaorMain/useMyMaor";
import { APPLICATION_ROUTES, PARTNERSHIP_STATUS } from "../../../constants";
import { observer } from "mobx-react-lite";
import Modal from "../../organisms/Modal";
// import { Link } from "react-router-dom";
import {
  // Authorization,
  PlanCanceled,
} from "../../organisms/RestrictionModal/RestrictionModal";
import { useEffect } from "react";
import { useContext } from "react";
import { StoreContext } from "../../../contexts";
// import PromotionalBanner from "../../organisms/PromotionalBanner";
import { getMode, getPackageDetails } from "../../../utilities/helpers";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ACTIONS } from "../../../store";
// import appDonationImage from "../../../assets/images/app-donation.jpg";
import maorShopImage from "../../../assets/images/maor-shop-2.png";
import writingCompetitionBanner from "../../../assets/images/writing-competition-banner.png";
import maorIcon from "../../../assets/images/writing-competition-logo.png";
import votingGif from "../../../assets/gifs/voting.gif";

import Icon from "../../atoms/Icon";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { COLORS } from "../../atoms/Icon/utilities";
// import { clarity } from 'react-microsoft-clarity';

const Layout = observer(({ children, logotype, data, size, page }) => {
  const scroll = useDocumentScroll();
  const maoShopModal = useModal();
  const writingcompetitionModal = useModal();
  const { partnership, authorization, freedonoraccess } =
    useContext(StoreContext);
  const [currentPlan, setCurrentPlan] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const { t } = useTranslation();
  const history = useHistory();

  const cancelAction = useModal();
  const query = useQuery();
  const { isProduction } = getMode();

  // FreeDonorAccess
  const fda = query.get("fda");
  let fdaSession = "";
  if (navigator.cookieEnabled) {
    fdaSession = sessionStorage.getItem("fda");
  }

  // Google and Clarity start
  useEffect(() => {
    console.log(isProduction, "Enviroment");
    if (authorization.isAuthenticated) {
      let membershipInfo = null;
      if (authorization.membership == null) {
        membershipInfo = "free";
      } else {
        membershipInfo = authorization.membership.memberClass;
      }

      if (isProduction) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "membershipInfo",
          userid: authorization.user.id,
          membership: membershipInfo,
        });

        let userIdForClarity = authorization.user.id;

        userIdForClarity = userIdForClarity.toString();
        window.clarity("set", "UserID", userIdForClarity);
        window.clarity("set", "Membership", membershipInfo);
        window.clarity("identify", userIdForClarity);

        console.log(
          "Called Clarity and GTAG with " +
            userIdForClarity +
            " " +
            membershipInfo
        );
      }
    } else {
      if (isProduction) {
        console.log(isProduction, "Enviroment");

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "membershipInfo",
          userid: null,
          membership: null,
        });

        window.clarity("set", "UserID", null);
        window.clarity("set", "Membership", null);

        console.log("Called Clarity and GTAG without signIn");
      }
    }
  }, [window.location.href, authorization.isAuthenticated]);
  //Google and Clarity closed

  if (fda || fdaSession) {
    const currentURL = window.location.href;
    let fdaURL = currentURL.replace(/&fda=1/, "");
    fdaURL = fdaURL.replace(/\?fda=1/, "");
    if (fda) {
      if (navigator.cookieEnabled) {
        sessionStorage.setItem("fda", fda);
      }
    }
    if (navigator.cookieEnabled) {
      sessionStorage.setItem("fdaURL", fdaURL);
    }
  }

  const onClickOverlayApp = (event) => {
    if (event.target.matches(`[data-name="video-overlay"]`))
      writingcompetitionModal.onClose();
    window.sessionStorage.setItem("writingcompetitionModal", true);
  };

  useEffect(() => {
    // if (!window.sessionStorage.getItem("writingcompetitionModal") == true) {
    // setTimeout(function () {
    // writingcompetitionModal.onClose();
    // }, 2000);
    // }
    const currentURL = window.location.href;

    if (window.sessionStorage.getItem("writingcompetitionModal") == "true") {
      writingcompetitionModal.onClose();
    } else {
      // if (!currentURL.includes("writing-competition")) {
      writingcompetitionModal.onOpen();
      // }
    }

    if (currentURL.includes("library")) {
      return;
    }
    let fdaURL = currentURL.replace(/&fda=1/, "");
    fdaURL = fdaURL.replace(/\?fda=1/, "");
    if (fda || fdaSession) {
      freedonoraccess[`${ACTIONS.FREE_DONOR_ACCESS.GET}`]({
        fdaURL,
      });
    }
  }, []);

  const { search, searching, onNavigateToSearch, coloringPages } = useMyMaor();

  useEffect(() => {
    if (
      navigator.cookieEnabled &&
      !window.sessionStorage.getItem("cancelPopupShown") == true
    ) {
      partnership[`${ACTIONS.PARTNERSHIP.GET_PARTNERSHIP}`]();
    }
  }, []);

  useEffect(() => {
    if (
      navigator.cookieEnabled &&
      !window.sessionStorage.getItem("cancelPopupShown") == true &&
      partnership.hasDetails
    ) {
      setCurrentPlan(
        partnership.details["plan"].toLowerCase().replace("-", "_")
      );
      setExpirationDate(partnership.details["expiration_date"]);

      if (
        partnership.details["status"] == PARTNERSHIP_STATUS.CANCELED &&
        Date.now() > new Date(partnership.details["expiration_date"])
      ) {
        setTimeout(() => {
          writingcompetitionModal.onClose();
          cancelAction.onOpen();
          if (navigator.cookieEnabled) {
            window.sessionStorage.setItem("cancelPopupShown", true);
          }
        }, 5000);
      } else {
        if (
          partnership.details["status"] != PARTNERSHIP_STATUS.EXPIRED &&
          partnership.details["status"] != PARTNERSHIP_STATUS.FREE
        ) {
          if (
            partnership.details["status"] == PARTNERSHIP_STATUS.PAUSED ||
            partnership.details["status"] == PARTNERSHIP_STATUS.PAST_DUE
          ) {
            setTimeout(() => {
              writingcompetitionModal.onClose();
              cancelAction.onOpen();
              if (navigator.cookieEnabled) {
                window.sessionStorage.setItem("cancelPopupShown", true);
              }
            }, 5000);
          }
        } else if (
          partnership.details["status"] == PARTNERSHIP_STATUS.EXPIRED
        ) {
          setTimeout(function () {
            cancelAction.onOpen();
            if (navigator.cookieEnabled) {
              window.sessionStorage.setItem("cancelPopupShown", true);
            }
          }, 5000);
        }
      }
    }
  }, [partnership.details]);

  const planName = () => {
    if (currentPlan) {
      const packageDetails = getPackageDetails(currentPlan);
      return t(packageDetails.NAME_TRANSLATION_KEY);
    } else {
      return "";
    }
  };

  if (data == undefined) {
    data = {
      autocomplete: {},
    };
    data.autocomplete = {
      search: search,
      searching: searching,
      coloringPages: coloringPages,
      onNavigateToSearch: onNavigateToSearch,
    };
  }
  data.autocomplete.onNavigateToSearch = onNavigateToSearch;

  return (
    <>
      {isMobile ? (
        <MobileView>
          <div className={classes.container}>
            {/* {!authorization.isUserMember && <PromotionalBanner />} */}
            <header
              className={cs(classes.header, classes.sticky, {
                // [classes.hidden]: scroll.vertical.direction === "down" && scroll.vertical.scrolled > 100,
              })}
            >
              <MobileHeader
                autocomplete={data.autocomplete}
                logotype={logotype}
                size={size}
                page={page}
              />
            </header>

            <main className={cs(classes.main, classes.mobileMain)}>
              {children}
            </main>

            {window.location.pathname.includes(APPLICATION_ROUTES.LIBRARY) ||
            window.location.pathname.includes(
              APPLICATION_ROUTES.MY_MAOR_MAIN
            ) ? (
              <footer className={classes.mobileFooter}>
                {/* <MobileFooter
                  coloringPages={data.coloringPages}
                  navigateToSearch={data.onNavigateToSearch}
                  audioHandler={data.audioHandler}
                /> */}
              </footer>
            ) : (
              <footer className={classes.footer}>
                <Footer />
              </footer>
            )}

            <div className={classes.fixed}>
              <PageScrollbar />
            </div>
          </div>
        </MobileView>
      ) : (
        <BrowserView>
          <div className={classes.container}>
            {/* {!authorization.isUserMember && <PromotionalBanner />} */}
            <header
              className={cs(classes.header, classes.sticky, {
                [classes.hidden]:
                  scroll.vertical.direction === "down" &&
                  scroll.vertical.scrolled > 100,
              })}
            >
              <Header logotype={logotype} size={size} />
            </header>

            <main className={classes.main}>
              <PageAnchor />
              {children}
            </main>
            <footer className={classes.footer}>
              <Footer />
            </footer>
            <div className={classes.fixed}>
              <PageScrollbar />
            </div>
          </div>
        </BrowserView>
      )}

      <Modal opened={maoShopModal.isOpened} style={maoShopModal.style}>
        <div
          data-name="video-overlay"
          className={classes.overlay}
          role="button"
          onClick={onClickOverlayApp}
        >
          <div className={classes.appDonationCard}>
            <div className={cs(classes.closeButtonContainer)}>
              <button
                className={classes.close}
                onClick={() => {
                  maoShopModal.onClose();
                  if (navigator.cookieEnabled) {
                    window.sessionStorage.setItem("maoShopPopupShown", true);
                  }
                }}
              >
                <Icon name="exit" size="l" color={COLORS.WHITE} />
              </button>
            </div>

            <a href="https://maor.shop/">
              <img src={maorShopImage} />
            </a>
          </div>
        </div>
      </Modal>

      {/* <Modal opened={writingcompetitionModal.isOpened} style={writingcompetitionModal.style}>
        <div
          data-name="video-overlay"
          className={classes.overlay}
          role="button"
          onClick={onClickOverlayApp}
        >
          <div className={cs(classes.appDonationCard, classes.writingCompetitionBanner)}>
            <div className={cs(classes.closeButtonContainer)}>
              <button
                className={classes.close}
                onClick={() => {
                  writingcompetitionModal.onClose();
                  if (navigator.cookieEnabled) {
                    window.sessionStorage.setItem("writingcompetitionModal", true);
                  }
                }}
              >
                <Icon name="exit" size="l" />
              </button>
            </div>
            <div className="wrapper-l wrapper-vertical-m">
            <div className={cs(classes.maorCompetitionLogoContainer)}>
              <img src={maorIcon} alt="maorIcon" className={cs(classes.maorCompetitionLogo)} />
            </div>
            <div className={cs(classes.writingCompetitionButton)} onClick={() => {
              history.push(APPLICATION_ROUTES.WRITINNG_COMPETITION)
              writingcompetitionModal.onClose();
            }}>
              Click Here
            </div>
            </div>
          </div>
        </div>
      </Modal> */}

      <Modal
        opened={writingcompetitionModal.isOpened}
        style={writingcompetitionModal.style}
      >
        <div
          data-name="video-overlay"
          className={classes.overlay}
          role="button"
          onClick={() => {
            writingcompetitionModal.onClose();
            window.sessionStorage.setItem("writingcompetitionModal", true);
          }}
        >
          <div className={cs(classes.appDonationCard, classes.votingModal)}>
            <div className={cs(classes.closeButtonContainer)}>
              <button
                className={classes.close}
                onClick={() => {
                  writingcompetitionModal.onClose();
                  if (navigator.cookieEnabled) {
                    window.sessionStorage.setItem(
                      "writingcompetitionModal",
                      true
                    );
                  }
                }}
              >
                <Icon name="exit" size="l" />
              </button>
            </div>
            <div
              onClick={() => {
                history.push(APPLICATION_ROUTES.WRITINNG_COMPETITION);
                writingcompetitionModal.onClose();
                window.sessionStorage.setItem("writingcompetitionModal", true);
              }}
            >
              <img src={votingGif} alt="maorIcon" />
            </div>
          </div>
        </div>
      </Modal>
      {partnership.hasDetails && expirationDate && (
        <PlanCanceled
          plan={planName()}
          partnership={partnership}
          popup={cancelAction}
          expirationDate={new Date(expirationDate)}
        />
      )}
    </>
  );
});

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  logotype: PropTypes.oneOf(Object.values(LOGOTYPE_NAMES)),
  size: PropTypes.oneOf([...Object.values(SIZES), ""]),

  data: PropTypes.object,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
